import { SafeHtml, SafeStyle, SafeUrl, SafeValue } from "@angular/platform-browser";
import { TLogger } from "@core/singletons/tlogger.singleton";

export class ContentMap extends Map<string, SafeValue> {
	constructor() {
		super();
		return Reflect.construct(Map, arguments, ContentMap);
	}

	set(key: string, value: SafeValue): this {
		return super.set(key.toLowerCase(), value);
	}

	get(key: string): SafeValue | undefined {
		key = key.toLowerCase();
		if (super.size > 0 && !super.has(key)) {
			TLogger.i.once('warn', `ContentMap is missing key: ${key}`);
		}
		return super.get(key) as SafeValue;
	}

	getRouterLink(key: string): string[] {
		key = key.toLowerCase();
		if (super.size > 0 && !super.has(key)) {
			TLogger.i.once('error', `ContentMap is missing router link key: ${key}`);
		}
		const result = super.get(key) as string;
		return result === undefined ? [] : [result];
	}

	getHtml(key: string): SafeHtml | undefined {
		return this.get(key) as SafeHtml;
	}

	getHtmlWithReplacements(contentKey: string, replacements: Map<string, string>): SafeHtml | undefined {
        let content = this.get(contentKey)!;
        replacements.forEach((value, key) => {
            content = (content.toString().replace(`{${key}}`, value)) as SafeHtml;
        });
		return content;
	}

	getHtmlMakeSpace(key: string): SafeHtml | undefined {
		let result = this.get(key) as SafeHtml;
		if (!result || result === '') {
			result = '&nbsp;';
		}
		return result as SafeHtml;
	}

	getUrl(key: string): SafeUrl | undefined {
		return this.get(key) as SafeUrl;
	}

	getStyle(key: string): SafeStyle | undefined {
		return this.get(key) as SafeStyle;
	}

	has(key: string): boolean {
		return super.has(key.toLowerCase()) as boolean;
	}

	hasValue(key: string): boolean {
        const contentValue = this.get(key)?.toString().trim();
        return contentValue !== undefined && contentValue !== '';
    }
}
